import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { BSBtn } from "traec-react/utils/bootstrap";
import { BreadCrumb } from "AppSrc/project/utils";
import { Spinner } from "traec-react/utils/entities";
import Traec from "traec";
import Im from "traec/immutable";
import Octicon from "react-octicon";
import { alertSuccess } from "traec-react/utils/sweetalert";

export class CompanyIndicatorTarget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetDidLoad: false,
      toDate: "last",
      byDate: Traec.Im.List(),
    };
    this.updateTarget = this.updateTarget.bind(this);
    this.updateReportingPeriod = this.updateReportingPeriod.bind(this);
    this.distributeValues = this.distributeValues.bind(this);

    this.requiredFetches = [
      new Traec.Fetch("company_target", "list"),
      new Traec.Fetch("company_indicator", "list"),
      new Traec.Fetch("company_report", "list", {
        toDate: "last",
        ignore_summary: true,
      }),
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
    this.updateTargetsInState();
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
    this.updateTargetsInState();
  }

  updateTargetsInState() {
    let { metricTarget } = this.props;
    let { targetDidLoad } = this.state;
    if (metricTarget && metricTarget.size && !targetDidLoad) {
      this.setState({
        target: metricTarget.get("value"),
        targetDidLoad: true,
        byDate: metricTarget.getInPath("meta_json.byDate") || Traec.Im.List(),
        threshold: this.getThresholdFromTarget(metricTarget),
        greenBelow: metricTarget.getIn(["meta_json", "greenBelow"]),
      });
    }
  }

  updateReportingPeriod(e, reportingPeriod, targetType) {
    e.preventDefault();
    let { byDate } = this.state;
    //debugger;
    let startDate = new Moment(reportingPeriod.get("startDate")).format("YYYY-MM-DD");
    let obj = byDate.filter((i) => i.get("startDate") == startDate).first();
    let newByDate = byDate
      .filter((i) => i.get("startDate") != startDate)
      .push(obj.set(targetType, parseFloat(e.target.value)));
    this.setState({ byDate: newByDate });
  }

  getThresholdFromTarget(target) {
    if (target.getIn(["meta_json", "thresholdLow"])) {
      return target.getIn(["meta_json", "thresholdLow"]);
    } else if (target.getIn(["meta_json", "thresholdHigh"])) {
      return target.getIn(["meta_json", "thresholdHigh"]);
    } else {
      return null;
    }
  }

  renderTitle() {
    let { indicator, company, project } = this.props;

    let indicatorName = indicator ? indicator.get("name") : "Indicator";

    return (
      <div className="mb-3 pb-3">
        <h3>{`Indicator Target: ${indicatorName}`}</h3>
        <BreadCrumb company={company} project={project} isRootRef={this.props.isRootRef} cref={this.props.cref} />
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="col-12 pb-1">
        <div className="row">
          <div className="col-3" />
          <div className="col-1">Target</div>
          <div className="col-1">Threshold</div>
        </div>
      </div>
    );
  }

  updateTarget(e, targetType) {
    e.preventDefault();
    this.setState({ [targetType]: e.target.value });
  }

  distributeValues(e) {
    e.preventDefault();
    let { reportingPeriods } = this.props;
    let { target, threshold } = this.state;
    let num_reportingPeriods = reportingPeriods.size;
    let sortedPeriods = reportingPeriods.sortBy((reportingPeriod) => reportingPeriod.get("startDate"));
    let runningTarget = 0;
    let runningThreshold = 0;

    let targetsByDate = sortedPeriods.toList().map((reportingPeriod) => {
      runningTarget = runningTarget + (target / num_reportingPeriods ? target / num_reportingPeriods : 0);
      runningThreshold = runningThreshold + (threshold / num_reportingPeriods ? threshold / num_reportingPeriods : 0);
      return Traec.Im.fromJS({
        target: runningTarget,
        threshold: runningThreshold,
        startDate: new Moment(reportingPeriod.get("startDate")).format("YYYY-MM-DD"),
        endDate: new Moment(reportingPeriod.get("endDate")).format("YYYY-MM-DD"),
      });
    });

    console.log("Updating targets by date", targetsByDate?.toJS());
    this.setState({ byDate: targetsByDate });
  }

  render() {
    let { reportingPeriods } = this.props;
    return (
      <div className="container-fluid">
        {this.renderTitle()}
        <FunctionalityHeader
          greenBelow={this.state.greenBelow}
          onButtonClick={(e) => this.setState({ greenBelow: !this.state.greenBelow })}
          companyId={this.props.companyId}
          metricTarget={this.props.metricTarget}
          baseMetricId={this.props.baseMetricId}
          target={this.state.target}
          threshold={this.state.threshold}
          targets={this.state.byDate}
          isRootRef={this.props.isRootRef}
        />
        {this.renderHeader()}
        <CompanyTarget
          updateTarget={this.updateTarget}
          distributeValues={this.distributeValues}
          target={this.state.target}
          threshold={this.state.threshold}
        />
        <div className="col-12">
          <ReportingPeriodRows
            reportingPeriods={reportingPeriods}
            byDate={this.state.byDate}
            updateReportingPeriod={this.updateReportingPeriod}
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const { indicatorId } = ownProps.match.params;
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);
  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let reportingPeriods = state.getInPath(`entities.companyReportingPeriods.byId.${companyId}`);
  let companyTargets = state.getInPath(`entities.companyObjects.byId.${companyId}.targets`) || Traec.Im.Map();
  let metricTargetMap = companyTargets.mapEntries(([k, v]) => [v.getInPath("metric.uid"), v]);

  let indicator = state.getInPath(`entities.companyObjects.byId.${companyId}.indicators.${indicatorId}`);
  let baseMetric = indicator ? indicator.get("resultBaseMetric") : Im.Map();
  let baseMetricId = baseMetric.get("uid");
  let metricTarget = metricTargetMap.get(baseMetricId);

  return {
    company,
    companyId,
    indicatorId,
    reportingPeriods,
    indicator,
    metricTarget,
    baseMetricId,
  };
};

export default connect(mapStateToProps)(CompanyIndicatorTarget);

function ReportingPeriodRows({ reportingPeriods, byDate, updateReportingPeriod }) {
  if (!reportingPeriods) {
    return (
      <div className="col-7 pt-2">
        <Spinner explanation="Loading Reporting Periods" timedOutComment="Could not load Reporting Periods" />
      </div>
    );
  }

  let sortedPeriods = reportingPeriods.sortBy((reportingPeriod) => reportingPeriod.get("startDate")).valueSeq();

  let targetMap = Traec.Im.Map();
  try {
    targetMap = byDate.reduce((a, c) => a.set(c.get("startDate"), c), Traec.Im.Map());
  } catch (err) {
    console.warn("Error producing targetMap", err);
    targetMap = Traec.Im.Map();
  }

  return sortedPeriods.map((reportingPeriod, index) => {
    let key = new Moment(reportingPeriod.get("startDate")).format("YYYY-MM-DD");
    return (
      <ReportingPeriodRow
        key={index}
        reportingPeriod={reportingPeriod}
        target={targetMap.get(key)?.get("target") || 0}
        threshold={targetMap.get(key)?.get("threshold") || 0}
        updateReportingPeriod={updateReportingPeriod}
      />
    );
  });
}

const FunctionalityHeader = (props) => {
  return (
    <div>
      <div className="col-12 pb-2">
        Desired performance above or below the targets and threshold?
        <BSBtn
          extra_className="ml-2 pl-2"
          noFloatRight={true}
          primaryOff={!!props.greenBelow}
          text={<Octicon name="arrow-up" />}
          onClick={props.onButtonClick}
        />
        <BSBtn
          noFloatRight={true}
          primaryOff={!props.greenBelow}
          text={<Octicon name="arrow-down" />}
          onClick={props.onButtonClick}
        />
      </div>
      <div className="col-12 pb-2">
        <SaveCumulativeTarget
          companyId={props.companyId}
          metricTarget={props.metricTarget}
          baseMetricId={props.baseMetricId}
          target={props.target}
          greenBelow={props.greenBelow}
          threshold={props.threshold}
          targets={props.targets}
        />
      </div>
    </div>
  );
};

const SaveCumulativeTarget = (props) => {
  const updateCumulativeTarget = (e) => {
    let { companyId, metricTarget, baseMetricId, target, targets, greenBelow, threshold } = props;
    e.preventDefault();
    let fetch = new Traec.Fetch("company_target", "post", {
      companyId,
    });

    if (metricTarget && metricTarget.size) {
      fetch = new Traec.Fetch("company_target", "patch", {
        companyId,
        metricTargetId: metricTarget.get("uid"),
      });
    }

    fetch.updateFetchParams({
      preFetchHook: (data) => ({
        metric: baseMetricId,
        value: target,
        date: Moment(),
        meta_json: {
          byDate: targets?.toJS(),
          greenBelow,
          thresholdLow: threshold,
        },
      }),
      postSuccessHook: (data) => {
        alertSuccess({
          text: `Thank you, the target has been updated`,
          onConfirm: () => {
            location.href = `/company/${props.companyId}/indicators`;
          },
        });
      },
    });
    fetch.dispatch();
  };

  return <BSBtn noFloatRight={true} text={"Save"} onClick={(e) => updateCumulativeTarget(e)} />;
};

const CompanyTarget = ({ target, threshold, updateTarget, distributeValues }) => {
  return (
    <div className="col-12 pb-2">
      <div className="row">
        <div className="col-3">
          <b>Project Target and Threshold:</b>
        </div>
        <input
          className="col-1 mr-2 form-control p-1"
          defaultValue={target}
          onChange={(e) => updateTarget(e, "target")}
        />
        <input
          className="col-1 mr-2 form-control p-1"
          defaultValue={threshold}
          onChange={(e) => updateTarget(e, "threshold")}
        />
        <BSBtn onClick={distributeValues} text={"Distribute across Reporting Periods"} />
      </div>
    </div>
  );
};

export const ReportingPeriodRow = ({ reportingPeriod, target, threshold, updateReportingPeriod }) => {
  return (
    <div className="row mb-2">
      <div className="col-1">{Moment(reportingPeriod.get("startDate")).format("Do MMM YY")}</div>
      <div className="col-1">{Moment(reportingPeriod.get("endDate")).format("Do MMM YY")}</div>
      <div className="col-1" />
      <input
        className={`col-1 mr-2 form-control p-1`}
        placeholder={target.toFixed(2)}
        onChange={(e) => updateReportingPeriod(e, reportingPeriod, "target")}
      />
      <input
        className={`col-1 mr-2 form-control p-1`}
        placeholder={threshold.toFixed(2)}
        onChange={(e) => updateReportingPeriod(e, reportingPeriod, "threshold")}
      />
    </div>
  );
};

export const getMetricTarget = (state, baseMetricId, commitId) => {
  let metricTargets = state.getInPath(`entities.commitEdges.byId.${commitId}.metricTargets`);
  try {
    let metricTarget = metricTargets.filter((target) => {
      if (target.getIn(["metric", "uid"]) === baseMetricId) {
        return target;
      }
    });
    return metricTarget.first();
  } catch (e) {
    return Im.Map();
  }
};
