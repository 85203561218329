import React from "react";
import { connect } from "react-redux";
import Traec from "traec";

//import IndicatorRow from "traec-react/equations";
import IndicatorRow from "AppSrc/project/indicators/equations";
import { confirmDelete, alertSuccess } from "traec-react/utils/sweetalert";

import { MetricTargetSet } from "AppSrc/dashboards/targetSet";

class CompanyIndicatorRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedIndicators: false,
    };

    this.toggleForm = this.toggleForm.bind(this);
    this.deleteIndicator = this.deleteIndicator.bind(this);
    this.deleteIndicatorFromTree = this.deleteIndicatorFromTree.bind(this);
    this.toggleSetTargetForm = this.toggleSetTargetForm.bind(this);
    this.toggleGreenBelow = this.toggleGreenBelow.bind(this);
    this.deleteTarget = this.deleteTarget.bind(this);
    this.pushToChildren = this.pushToChildren.bind(this);
    this.pushTargetToChildren = this.pushTargetToChildren.bind(this);
    this.deleteTargetFromTree = this.deleteTargetFromTree.bind(this);
  }

  /**********************
   COMPONENT METHODS
   **********************/

  /**********************
   MENU OPERATIONS
   **********************/

  dropDownLinks() {
    let { indicator } = this.props;
    let metricTarget = indicator.get("metricTarget");
    let raThreshold = metricTarget ? metricTarget.getInPath("meta_json.thresholdLow") : null;
    let greenBelow = metricTarget ? metricTarget.getInPath("meta_json.greenBelow") : null;

    let items = [
      { name: "Set Targets", onClick: this.toggleSetTargetForm },
      {
        name: "Set Cumulative Target",
        linkTo: `/company/${this.props.companyId}/indicator/${this.props.indicator.get("uid")}/target`,
      },
    ];
    if (metricTarget && !raThreshold) {
      let name = greenBelow ? "Set Green Above Target" : "Set Green Below Target";
      items.push({ name: name, onClick: this.toggleGreenBelow });
    }

    items.push({});
    items.push({ name: "Add indicator to children", onClick: this.pushToChildren });
    items.push({ name: "Delete indicator from here only", onClick: this.deleteIndicator });
    items.push({ name: "Delete indicator from here and children", onClick: this.deleteIndicatorFromTree });

    if (indicator.get("metricTarget")) {
      items.push({});
      items.push({ name: "Add target to children", onClick: this.pushTargetToChildren });
      items.push({ name: "Delete target from here only", onClick: this.deleteTarget });
      items.push({ name: "Delete target from here and children", onClick: this.deleteTargetFromTree });
    }

    return items;
  }

  deleteIndicator(e) {
    e.preventDefault();
    let { companyId, indicator } = this.props;
    let indicatorId = indicator.get("uid");
    let indicatorName = indicator.getInPath("metric.name");

    confirmDelete({
      text: `This will delete this indicator ${indicatorName}.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        new Traec.Fetch("company_indicator", "delete", { companyId, indicatorId }).dispatch();
      },
    });
  }

  deleteIndicatorFromTree(e) {
    e.preventDefault();
    let { companyId, indicator } = this.props;
    let indicatorId = indicator.get("uid");
    let indicatorName = indicator.getInPath("metric.name");

    confirmDelete({
      text: `This will delete this indicator ${indicatorName} from here and all child business units, projects and reporting packages.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
        fetch.updateFetchParams({
          body: {
            type: "DELETE_INDICATOR_FROM_ALL",
            payload: { indicatorId },
          },
          postSuccessHook: () => {
            alertSuccess({
              text: `Sucessfully removed indicator from this level and all children.`,
              onConfirm: () => {
                location.reload();
              },
            });
          },
        });
        fetch.dispatch();
      },
    });
  }

  pushToChildren(e) {
    e.preventDefault();
    let { companyId, indicator } = this.props;
    let indicatorId = indicator.get("uid");
    let indicatorName = indicator.getInPath("metric.name");

    let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
    fetch.updateFetchParams({
      body: {
        type: "ADD_INDICATOR_TO_ALL",
        payload: { indicatorId },
      },
      postSuccessHook: () => {
        alertSuccess({
          text: `Sucessfully added indicator to all children.`,
          onConfirm: () => {
            location.reload();
          },
        });
      },
    });

    alertSuccess({
      iconType: "warning",
      title: "Adding to children",
      text: `This will add the indicator ${indicatorName} to all child business units, projects and reporting packages`,
      onConfirm: () => {
        fetch.dispatch();
      },
    });
  }

  pushTargetToChildren(e) {
    e.preventDefault();
    let { companyId, indicator } = this.props;
    let indicatorId = indicator.get("uid");
    let indicatorName = indicator.getInPath("metric.name") || "";
    let metricTargetId = indicator.getInPath("metricTarget.uid") || null;

    let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
    fetch.updateFetchParams({
      body: {
        type: "ADD_TARGET_TO_ALL",
        payload: { indicatorId, metricTargetId },
      },
      postSuccessHook: () => {
        alertSuccess({
          text: `Sucessfully added target for indicator ${indicatorName} to all children.`,
          onConfirm: () => {
            location.reload();
          },
        });
      },
    });

    alertSuccess({
      iconType: "warning",
      title: "Adding to children",
      text: `This will add the target for indicator ${indicatorName} to all child business units, projects and reporting packages`,
      onConfirm: () => {
        fetch.dispatch();
      },
    });
  }

  deleteTarget(e) {
    e.preventDefault();
    let { companyId, indicator } = this.props;
    let indicatorName = indicator.getInPath("metric.name") || "";
    let metricTargetId = indicator.getInPath("metricTarget.uid") || null;
    if (!metricTargetId) {
      return null;
    }

    confirmDelete({
      text: `This will delete the target set for indicator ${indicatorName}.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        new Traec.Fetch("company_target", "delete", {
          companyId,
          metricTargetId,
        }).dispatch();
      },
    });
  }

  deleteTargetFromTree(e) {
    e.preventDefault();
    let { companyId, indicator } = this.props;
    let indicatorId = indicator.get("uid");
    let indicatorName = indicator.getInPath("metric.name");
    let metricTargetId = indicator.getInPath("metricTarget.uid") || null;

    confirmDelete({
      text: `This will delete the target for indicator ${indicatorName} from here and all child business units, projects and reporting packages.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
        fetch.updateFetchParams({
          body: {
            type: "DELETE_TARGET_FROM_ALL",
            payload: { indicatorId, metricTargetId },
          },
          postSuccessHook: () => {
            alertSuccess({
              text: `Sucessfully removed target for indicator from this level and all children.`,
              onConfirm: () => {
                location.reload();
              },
            });
          },
        });
        fetch.dispatch();
      },
    });
  }

  toggleGreenBelow(e) {
    e.preventDefault();
    let { companyId, indicator } = this.props;
    let metricTarget = indicator.get("metricTarget");
    if (!metricTarget) {
      return null;
    }
    let metricTargetId = metricTarget.get("uid") || null;
    let greenBelow = metricTarget ? metricTarget.getInPath("meta_json.greenBelow") : null;

    let fetch = new Traec.Fetch("company_target", "patch", { companyId, metricTargetId });
    fetch.updateFetchParams({
      preFetchHook: (data) => ({ meta_json: { greenBelow: !greenBelow } }),
    });
    fetch.dispatch();
  }

  toggleForm(e) {
    e.preventDefault();
    this.setState({ showNewForm: !this.state.showNewForm });
  }

  toggleSetTargetForm(e) {
    e.preventDefault();
    this.setState({ showSetTargetForm: !this.state.showSetTargetForm });
  }

  /**********************
   * RENDER METHODS
   **********************/

  render_set_targets() {
    let { indicator, dispatch, companyId } = this.props;
    if (!this.state.showSetTargetForm) {
      return null;
    }
    return (
      <MetricTargetSet
        header={<hr />}
        indicator={indicator}
        companyId={companyId}
        dispatch={dispatch}
        closeButtonHandler={this.toggleSetTargetForm}
      />
    );
  }

  render() {
    let { baseMetrics, indicator } = this.props;

    return (
      <IndicatorRow
        baseMetrics={baseMetrics}
        indicator={indicator}
        dropDownLinks={this.dropDownLinks()}
        setTargetComponent={this.render_set_targets()}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapDispatchToProps)(CompanyIndicatorRow);
