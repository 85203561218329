import Traec from "traec";
import { companyPermissionFilter } from "traec/utils/permissions/company";
import { setNavBarItems } from "traec-react/navBar";
import { mergeInUI } from "traec/redux/actionCreators";
import store from "traec/redux/store";
import { getTerm } from "AppSrc/tree/utils";

const navBarLinks = (props) => {
  let { companyId } = props;

  let _companyId = companyId ? companyId.substring(0, 8) : companyId;
  let companyBase = `/company/${_companyId}`;

  let items = [
    {
      label: getTerm("Company", props),
      requiresAdmin: false,
      to: [
        { label: "Dashboard", to: companyBase, octicon: "home" },
        {
          label: "Members",
          to: `${companyBase}/members`,
          octicon: "organization",
          requiresAdmin: false,
          requiredActions: ["READ_COMPANY_MEMBER"],
        },
      ],
    },
    {
      label: "Admin",
      requiresAdmin: true,
      to: [
        { label: "Indicators", to: `${companyBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
        { label: "Settings", to: `${companyBase}/details`, octicon: "gear", requiresAdmin: true },
        { label: null },
        { label: "Email Settings", to: `${companyBase}/email/settings`, octicon: "inbox", requiresAdmin: true },
        { label: "Email Statistics", to: `${companyBase}/email/report`, octicon: "mail", requiresAdmin: true },
      ],
    },
  ];
  return companyPermissionFilter(companyId, items);
};

export const setNavBar = (props) => {
  let { companyId } = props;
  let _navBarLinks = navBarLinks(props);
  store.dispatch(setNavBarItems(_navBarLinks));
  store.dispatch(
    mergeInUI(
      {
        id: companyId,
        type: "company",
      },
      {
        itemPath: "navbar",
      }
    )
  );
};
