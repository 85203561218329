import React from "react";

export const companyFields = {
  name: { value: "", class: "col", endRow: true },
  address: { label: "Address 1", value: "", class: "col", endRow: true },
  address2: { label: "Address 2", value: "", class: "col", endRow: true },
  suburb: { label: "Suburb/Town", value: "", class: "col" },
  state: { label: "State/County", value: "", class: "col" },
  postcode: { value: "", class: "col" },
  country: { value: "", class: "col", endRow: true },

  contact_email: { label: "Company email", value: "", class: "col" },
  website: { label: "Website", value: "", class: "col" },

  //Meta fields
  meta_telephone: { label: "Company Telephone", value: "", class: "col", endRow: true },
  meta_description: { label: "Business Description", value: "", class: "col", endRow: true },
  meta_companysize: { label: "Company Size", value: "", class: "col" },
  meta_sector: { label: "Business Sector", value: "", class: "col", endRow: true },

  // Select the dashboard type to display
  meta_dashboard: {
    label: "Dashboard display",
    defaultValue: "icons",
    value: null,
    class: "col",
    inputType: "select",
    endRow: true,
    options: [
      <option key={1} value={"icons"}>
        RAG Icons
      </option>,
      <option key={2} value={"map_uk"}>
        Map/Chloropleth (UK)
      </option>,
    ],
  },
  // END Meta Fields

  reg_number: { label: "Company Reg Num", value: "", class: "col", endRow: true },
};
