import React from "react";
import EntityReportBlock from "./entityBlock";
import { BSBtn, BSCard } from "traec-react/utils/bootstrap";
import { alertSuccess } from "traec-react/utils/sweetalert";
import Octicon from "react-octicon";

export const ProjectList = (props) => {
  const [state, setState] = React.useState({
    selected_project: null,
  });

  let { configPath, setConfig, projects, all_projects, parent_groups } = props;

  const addProject = () => {
    let { selected_project } = state;
    // Throw a warning if no project is selected
    if (!selected_project) {
      alertSuccess({ title: "Select a project to add", text: "", iconType: "warning" });
      return null;
    }
    // Get the appropriate project and default add the parent groupings
    let project = all_projects.filter((i) => i.get("uid") == selected_project).first();
    project = project.set("indicator_groups", parent_groups);
    // Set the new project into the list
    let new_projects = projects.push(project);
    //console.log("Adding new projects", new_projects)
    setConfig(configPath, { projects: new_projects });
  };

  const deleteProject = (index) => {
    let new_projects = projects.splice(index, 1);
    setConfig(configPath, { projects: new_projects });
  };

  const moveProject = (index, move_dist) => {
    let new_index = index + move_dist;
    new_index = new_index > projects.size ? projects.size : new_index;
    new_index = new_index < 0 ? 0 : new_index;
    let item = projects.get(index);
    let new_projects = projects.splice(index, 1);
    new_projects = new_projects.splice(new_index, 0, item);
    setConfig(configPath, { projects: new_projects });
  };

  const selectProject = (e) => {
    //console.log("SELECTED PROJECT", e.target.value)
    setState({ selected_project: e.target.value });
  };

  //console.log("Rendering project components", projects ? projects.toJS() : null)
  let project_components = projects.map((project, index) => {
    return (
      <Project
        key={index}
        index={index}
        configDict={project}
        configPath={`${configPath}.projects.${index}`}
        setConfig={setConfig}
        deleteProject={deleteProject}
        moveProject={moveProject}
      />
    );
  });

  return (
    <div>
      <h3 className="mt-2 mb-2 pt-2 pb-2">Projects</h3>

      <div className="row">
        <div className="col-sm-6" />
        <div className="col-sm-4">
          <ProjectDropdown
            projects={all_projects}
            selectProject={selectProject}
            selected_project={state.selected_project}
          />
        </div>
        <div className="col-sm-2">
          <BSBtn text={"Add Project"} onClick={(e) => addProject(e)} />
        </div>
      </div>

      {project_components.size ? <hr /> : null}
      {project_components}
    </div>
  );
};

const Project = (props) => {
  let { configDict, configPath, setConfig, deleteProject, moveProject, index } = props;

  const setConfigField = (value, field) => {
    if (field) {
      setConfig(configPath, { configDict: configDict.set(field, value) });
    } else {
      setConfig(configPath, value);
    }
  };

  let is_hidden = configDict.get("hidden");

  return (
    <BSCard
      title={
        <span>
          {`Project: ${configDict.get("name")}`}
          <Octicon
            name={is_hidden ? "chevron-right" : "chevron-down"}
            className="ml-2"
            onClick={() => setConfigField(!is_hidden, "hidden")}
          />
        </span>
      }
      extraBodyClass={is_hidden ? "m-0 p-0" : ""}
      body={
        is_hidden ? null : (
          <React.Fragment>
            <EntityReportBlock
              indent={1}
              configPath={configPath}
              configDict={configDict}
              setConfig={setConfig}
              projectId={configDict.get("uid")}
              includeSubElements={false}
            />
            <hr />
            <BSBtn text="Delete" extra_className={"border-danger bg-danger"} onClick={() => deleteProject(index)} />
            <BSBtn text="Move Up" extra_className={"ml-1 mr-1"} onClick={() => moveProject(index, -1)} />
            <BSBtn text="Move Down" extra_className={"ml-1 mr-1"} onClick={() => moveProject(index, 1)} />
          </React.Fragment>
        )
      }
    />
  );
};

const ProjectDropdown = (props) => {
  let { projects, selectProject, name, extraClass, selected_project } = props;

  let options = projects.toList().map((project, i) => (
    <option key={i} value={project.get("uid")}>
      {project.get("name")}
    </option>
  ));
  options = options.unshift(
    <option key={-1} value={""}>
      Select a project:
    </option>
  );

  return (
    <div className={`form-group ${extraClass}`}>
      <select
        className={`custom-select`}
        onChange={selectProject}
        name={"Select Project"}
        value={selected_project || ""}
      >
        {options}
      </select>
    </div>
  );
};
