import React from "react";
import { connect } from "react-redux";

import { BSCard, BSBtn, BSModal } from "traec-react/utils/bootstrap";

import ProjectItem from "./projectItem";
import CompanyProjectForm from "./projectForm";

function ProjectList(props) {
  let { projects, user, dispatch } = props;
  if (!projects) {
    return null;
  }
  return projects.map((project, i) => (
    <ProjectItem key={i} index={i} project={project} user={user} dispatch={dispatch} />
  ));
}

class CompanyProjects extends React.Component {
  render() {
    let { company, companyId } = this.props;
    let modalId = `ADD_PROJECT_${companyId}`;

    return (
      <div className="row">
        <BSCard
          widthOffset="col-sm-12"
          title="Projects at this level"
          button={
            <BSBtn
              onClick={(e) => {
                $(`#${modalId}`).modal("show");
              }}
              text="Add a Project"
            />
          }
          body={<ProjectList {...this.props} />}
          form={
            <BSModal
              id={modalId}
              title="Add a new Project"
              body={<CompanyProjectForm companyId={companyId} company={company} modalId={modalId} />}
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { companyId } = ownProps;
  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let projects = company.get(`projects`);
  let user = state.getInPath("auth.user");
  return { company, projects, user };
};

export default connect(mapStateToProps)(CompanyProjects);
