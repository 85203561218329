import React from "react";
import Traec from "traec";
import Select from "react-dropdown-select";
import { BSCard } from "traec-react/utils/bootstrap";
import { TextField } from "./textField";
import { BSBtn } from "traec-react/utils/bootstrap/btn";
import Octicon from "react-octicon";

const IssueSelector = (props) => {
  let { indicators, setConfigField, extraClass } = props;

  let issues = indicators.toList().reduce((issue_map, i) => {
    let issue_name = i.getInPath("resultBaseMetric.category");
    let indicator_list = issue_map[issue_name] || [];
    indicator_list.push({ name: i.get("name"), uid: i.get("uid") });
    issue_map[issue_name] = indicator_list;
    return issue_map;
  }, {});

  const handleChange = (e) => {
    let issue_name = e.target.value;
    let new_indicators = Traec.Im.fromJS(issues[issue_name]);
    setConfigField({
      indicators: new_indicators,
      name: issue_name,
    });
  };

  let options = Object.keys(issues).map((name, i) => (
    <option key={i} value={name}>
      {name}
    </option>
  ));
  options.unshift(
    <option key={-1} value={""}>
      Create from issue:
    </option>
  );

  return (
    <div className={`form-group ${extraClass}`}>
      <select className={`custom-select`} onChange={handleChange} name={"Select Issue"} value={""}>
        {options}
      </select>
    </div>
  );
};

const IndicatorList = (props) => {
  let { indicators, selectedIndicators, selectIndicators } = props;

  let options = [];
  try {
    options = indicators
      .map((indicator) => ({ name: indicator.get("name"), uid: indicator.get("uid") }))
      .toList()
      .toJS();
  } catch (e) {}

  return (
    <div>
      <Select
        values={selectedIndicators ? selectedIndicators.toJS() : []}
        options={options}
        labelField={"name"}
        valueField={"uid"}
        multi={true}
        clearable={true}
        placeholder="Select Indicators"
        onChange={(e) => selectIndicators(e)}
      />
    </div>
  );
};

export const IndicatorGroup = (props) => {
  let { index, configDict, configPath, setConfig, indicators, deleteGroup, moveGroup } = props;

  const setConfigField = (value, field) => {
    if (field) {
      setConfig(configPath, { configDict: configDict.set(field, value) });
    } else {
      setConfig(configPath, value);
    }
  };

  const selectIndicators = (e) => {
    setConfigField(Traec.Im.fromJS(e), "indicators");
  };

  let is_hidden = configDict.getInPath(`hidden`);

  return (
    <BSCard
      title={
        <span>
          {`Indicator Group: ${configDict.getInPath(`name`)}`}
          <Octicon
            name={is_hidden ? "chevron-right" : "chevron-down"}
            className="ml-2"
            onClick={() => setConfig(configPath, configDict.set("hidden", !is_hidden))}
          />
        </span>
      }
      extraBodyClass={is_hidden ? "m-0 p-0" : ""}
      body={
        is_hidden ? null : (
          <React.Fragment>
            <IndicatorGroupBody
              indicators={indicators}
              selectIndicators={selectIndicators}
              handleChange={setConfigField}
              configDict={configDict}
              setConfigField={setConfigField}
              setConfig={setConfig}
              configPath={configPath}
            />
            <hr />
            <BSBtn text="Delete" extra_className={"border-danger bg-danger"} onClick={() => deleteGroup(index)} />
            <BSBtn text="Move Up" extra_className={"ml-1 mr-1"} onClick={() => moveGroup(index, -1)} />
            <BSBtn text="Move Down" extra_className={"ml-1 mr-1"} onClick={() => moveGroup(index, 1)} />
          </React.Fragment>
        )
      }
    />
  );
};

export const IndicatorGroupBody = (props) => {
  let { configDict, setConfigField, configPath, setConfig, indicators, selectIndicators } = props;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-2">
          <b>Group Name</b>
        </div>
        <input
          className="form-control col-sm-4"
          placeholder={"Give indicator group a name"}
          type="text"
          value={configDict.get("name") || ""}
          onChange={(e) => setConfig(configPath, configDict.set("name", e.target.value))}
        />
        <div className="col-sm-2"></div>
        <div className="col-sm-4">
          <IssueSelector indicators={indicators || Im.List()} setConfigField={setConfigField} />
        </div>
      </div>

      <TextField type={"preamble"} configDict={configDict} setConfig={setConfig} configPath={configPath} />

      <h4 className="pt-3 mt-3">Select Indicators To Include in Group</h4>
      <IndicatorList
        indicators={indicators}
        selectIndicators={selectIndicators}
        selectedIndicators={configDict.get("indicators")}
      />

      <TextField type={"postamble"} configDict={configDict} setConfig={setConfig} configPath={configPath} />
    </React.Fragment>
  );
};
